<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Объект
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные объекта</FormCardTitle>
          <FormInputBlock>
            <ValidationAutocompleteField
              @input="clearService"
              :search-function="citySearch"
              rules="required"
              label="Город"
              v-if="[70].includes(currentRole)"
              v-model="city"
              validate-name="город"
            />
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="название"
            ></ValidationInputField>
            <ValidationInputField
              v-model="address"
              rules="required"
              label="Адрес"
              validate-name="адрес"
            ></ValidationInputField>
            <ValidationSelectField
              :items="objectsType"
              rules="required"
              label="Тип объекта"
              v-model="type"
              validate-name="тип объекта"
            />
            <!-- :disabled="!!organization || !!dealerId || !!companyId" -->
            <ValidationAutocompleteField
              :search-function="dillerSearch"
              v-if="[70].includes(currentRole)"
              label="Дилер"
              v-model="diller"
              validate-name="дилер"
            />
            <!-- :disabled="!!companyId" -->
            <ValidationAutocompleteField
              :search-function="companySearch"
              v-if="[70, 60].includes(currentRole)"
              :rules="
                organization == null || organization == '' ? 'required' : ''
              "
              label="Компания"
              v-model="organization"
              validate-name="компания"
            />
            <ValidationAutocompleteField
              :search-function="serviceCompanySearch"
              label="Обслуживающая организация"
              v-model="service_organization"
              validate-name="обслуживающая организация"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editObject)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { getOrganizationsRequest } from "@/helpers/api/organizations";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import { getCitiesClearRequest } from "@/helpers/api/cities";
export default {
  name: "EditObjectPage",
  components: {
    ValidationSelectField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      objectsType: [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ],
      citySearch: async value => {
        const query = {};

        if (typeof value === "string") {
          query.search = value;
        } else {
          query.ids = value;
        }
        const res = (await getCitiesClearRequest(query)).data.results.map(
          el => ({
            text: el.name,
            value: el.id
          })
        );

        return res;
      },
      serviceCompanySearch: async value => {
        return (
          await getOrganizationsRequest({
            query: { ids: value, types: "service", city: this.city }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      dillerSearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              ids: value,
              types: "diller,oppen",
              city: this.city
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      companySearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              ids: value,
              types: "default",
              city: this.city,
              diller: this.diller
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  created() {
    this.$store.dispatch("prepareEditObject", {
      id: this.$route.params.id
    });
  },
  watch: {
    diller() {
      if (this.$store.getters.getObjectEditForm.organization_diller.isEdit) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "organization",
          value: null
        });
      }
    }
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    address: {
      get() {
        return this.$store.getters.getObjectEditForm.address.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "address",
          value: newValue
        });
      }
    }, //id города
    service_organization: {
      get() {
        return this.$store.getters.getObjectEditForm.service_organization.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "service_organization",
          value: newValue
        });
      }
    }, //id города
    diller: {
      get() {
        return this.$store.getters.getObjectEditForm.organization_diller.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "organization_diller",
          value: newValue
        });
      }
    },
    organization: {
      get() {
        return this.$store.getters.getObjectEditForm.organization.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "organization",
          value: newValue
        });
      }
    },
    city: {
      get() {
        return this.$store.getters.getObjectEditForm.city.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    name: {
      get() {
        return this.$store.getters.getObjectEditForm.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "name",
          value: newValue
        });
      }
    }, //name,
    type: {
      get() {
        return this.$store.getters.getObjectEditForm.type.value;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "type",
          value: newValue
        });
      }
    } //name
  },
  methods: {
    clearService() {
      this.service_organization = null;
    },
    editObject() {
      this.loading = true;
      this.$store
        .dispatch("editObject", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.object)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
